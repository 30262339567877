<template>
  <div class="search-result" :style="{height: showDetail ? '1600px' : '1300px'}">
    <div class="banner">
      <div class="mask"></div>
    </div>

    <div class="search-area">
      <img class="search-area__logo" src="../../assets/img/search/logo-text-blue.png" alt="财商少年" >
      <div class="search-area__form">
        <div class="search-area__input">
          <input type="text" v-model="search" @keypress.enter="searchTeacher">
        </div>
        <div class="search-area__btn" @click="searchTeacher"></div>
      </div>
      <div class="points-title">查询结果</div>
      <img class="no-result" src="../../assets/img/search/none.png" v-if="!resultList.length">
      <p class="no-result__text" v-if="!resultList.length">抱歉，没有查找到相关的导师信息！</p>
      <div class="search-area__result-container" v-if="resultList.length">
        <div class="search-area__result" v-for="(item, index) in resultList" :key="index">
          <div class="search-area__result-top">
            <div class="photo">
              <img :src="item.avatar">
            </div>
            <div class="content">
              <div class="content-id">编号: {{item.fqcNumber}}</div>
              <div>
                <div class="content-title">少年财商素质教育导师</div>
                <div class="content-text">姓名: {{item.userName}}</div>
                <div class="content-text">性别: {{item.gender === '1' ? '男' : item.gender === '2' ? '女' : '空'}}</div>
                <div class="content-text">地区: {{item.address}}</div>
                <div class="content-btn" @click="show(item)">详细查询</div>
              </div>
            </div>
          </div>
          <div class="search-area__result-bottom"></div>
        </div>
      </div>
    </div>

    <search-result-detail v-show="showDetail" @close="showDetail = false" :teacher="teacherData"/>
  </div>
</template>

<script>
  import SearchResultDetail from "@/views/search/SearchResultDetail";
  export default {
    name: "SearchResult",
    components: {
      SearchResultDetail
    },
    data() {
      return {
        search: '', // 用户搜索的关键词
        showDetail: false,
        noResult: false,
        resultList: [],
        teacherData: {},
      }
    },
    methods: {
      // 显示导师详情
      show(item) {
        this.teacherData = item
        this.showDetail = true
      },
      // 搜索
      searchTeacher() {
        if(!this.search) {
          this.resultList = []
          return
        }
        this.$axios.getTeachers({
          search: this.search,
          page: 0, // 首页是0
          limit: 3
        }).then(res => {
          this.resultList = res.data.list
        })
      }
    },
    created() {
      this.search = this.$route.query.search
      this.searchTeacher()
    }
  }
</script>

<style lang="scss" scoped>
  $red: #e40073;
  $green: #95c51b;
  $yellow: #ffe100;
  $blue: #00abeb;
  $deep-blue: #0068b7;
  .search-result {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB","Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    min-width: 1900px;
    transition: height 0.7s;
    &:before {
      content: '';
      display: table;
    }
  }
  .banner {
    height: 600px;
    min-width: 1900px;
    width: 100%;
    background-image: url("../../assets/img/search/banner.webp");
    background-size: cover;
    background-position: center;
    position: absolute;
    text-align: center;
  }

  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/img/search/mask.png");
    background-position: center;
    background-size: cover;
    z-index: 1;
  }

  .search-area {
    width: 1350px;
    min-height: 1000px;
    background-color: #f2f2f2;
    margin: 120px auto;
    position: relative;
    z-index: 2;
    &:before {
      content: '';
      display: table;
    }
  }

  .search-area__logo {
    display: block;
    margin: 30px auto 40px;
    width: 168px;
    height: 168px;
  }

  .points-title {
    width: 379px;
    height: 48px;
    display: block;
    margin: 70px auto 0;
    background-image: url("../../assets/img/search/points.png");
    background-size: 100%;
    background-position: center;

    color: #2d3233;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
  // 搜索表单
  .search-area__form {
    display: flex;
    width: 485px;
    margin: 0 auto;
  }
  // 输入框
  .search-area__input {
    width: 420px;
    height: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    input {
      font-size: 28px;
      line-height: 50px;
      width: 420px;
      padding: 0 18px;
      outline: none;
    }
  }
  // 搜索按钮
  .search-area__btn {
    height: 50px;
    width: 50px;
    background-image: url("../../assets/img/search/search-blue.png");
    background-size: 100%;
    margin-left: 20px;
    cursor: pointer;
  }

  // 搜索结果
  .no-result {
    width: 341px;
    height: 316px;
    display: block;
    margin: 80px auto 0;
  }
  .no-result__text {
    text-align: center;
    font-size: 23px;
    color: #999999;
  }
  .search-area__result-container {
    display: flex;
    justify-content: space-around;
    width: 1290px;
    margin: 45px auto 0;
  }
  .search-area__result {
    width: 410px;
    height: 230px;
    background-color: $deep-blue;
    border-radius: 5px;
    margin-right: 30px;
    &:last-child {
      margin: 0;
    }
    box-shadow: 5px 5px 15px #33333355;
  }
  .search-area__result-top {
    padding: 0 22px;
    display: flex;
    .photo {
      width: 100px;
      height: 150px;
      background-color: #ffffff;
      border-radius: 5px;
      margin-top: 32px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .content {
      margin-left: 22px;
      flex: 1;
      letter-spacing: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content-id {
        color: #ffffff;
        font-size: 12px;
        margin-top: 10px;
        text-align: right;
      }
      .content-title {
        color: $yellow;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .content-text {
        color: #ffffff;
        font-size: 14px;
      }
      .content-btn {
        margin-top: 7px;
        width: 80px;
        height: 20px;
        background-color: $blue;
        color: #ffffff;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .search-area__result-bottom {
    height: 10px;
    background-color: $blue;
    margin-top: 18px;
  }
</style>