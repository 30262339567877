<template>
  <transition name="detail">
    <div class="search-result-detail">
      <img class="logo" src="../../assets/img/search/logo-text-white.png" alt="财商少年">
      <div class="detail">
        <img class="delete" src="../../assets/img/search/delete.png" @click="close">
        <div class="mask"></div>
        <div class="banner">
          <div class="banner__title">少年财商素质教育导师</div>
          <div class="banner__main">
            <div class="banner__photo">
              <img :src="teacher.avatar">
            </div>
            <div class="banner__key-value-container">
              <div class="banner__key-value">
                <div class="banner__key">姓名：</div>
                <div class="banner__value">{{teacher.userName}}</div>
              </div>
              <div class="banner__key-value">
                <div class="banner__key">导师职称：</div>
                <div class="banner__value">{{teacher.leadName}}</div>
              </div>
              <div class="banner__key-value">
                <div class="banner__key">性别：</div>
                <div class="banner__value">{{teacher.gender === '1' ? '男' : teacher.gender === '2' ? '女' : '空'}}</div>
              </div>
              <div class="banner__key-value">
                <div class="banner__key">地区：</div>
                <div class="banner__value">{{teacher.address}}</div>
              </div>
              <div class="banner__key-value">
                <div class="banner__key">导师编号：</div>
                <div class="banner__value">{{teacher.fqcNumber}}</div>
              </div>
              <div class="banner__key-value" v-if="teacher.classInfo">
                <div class="banner__key">导师班期数：</div>
                <div class="banner__value">{{teacher.classInfo}}</div>
              </div>
<!--              广财字段-->
              <div class="banner__key-value" v-if="teacher.gcFqcNumber">
                <div class="banner__key">广财学员号：</div>
                <div class="banner__value">{{teacher.gcFqcNumber}}</div>
              </div>
              <div class="banner__key-value" v-if="teacher.gcClassInfo">
                <div class="banner__key">广财班期数：</div>
                <div class="banner__value">{{teacher.gcClassInfo}}</div>
              </div>
            </div>
          </div>
          <div class="banner__line"></div>
        </div>

        <div class="points-title">证书文件</div>
        <div class="file">
          <img :src="teacher.images">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "SearchResultDetail",
    props: {
      teacher: Object
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>
  $red: #e40073;
  $green: #95c51b;
  $yellow: #ffe100;
  $blue: #00abeb;
  $deep-blue: #0068b7;

.search-result-detail {
  position: absolute;
  min-width: 1900px;
  width: 100%;
  height: calc(100% - 74px);
  top: 74px; // header height
  z-index: 3;
  background-color: #33333388;
}
.logo {
  display: block;
  margin: 150px auto 40px;
  height: 168px;
  width: 168px;
}
.delete {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  top: -35px;
  cursor: pointer;
}
.detail {
  width: 1200px;
  height: 1200px; // 他妈的这么长, 比整个页面都长
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  &:before {
    content: '';
    display: table;
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../../assets/img/search/bg.png");
  background-size: 100%;
}
  .banner {
    background-color: $deep-blue;
    height: 360px;
    width: 1140px;
    margin: 30px auto;
    border-radius: 5px;
    &:before {
      content: '';
      display: table;
    }
  }
  .banner__title {
    color: $yellow;
    font-size: 50px;
    margin-top: 43px;
    text-align: center;
  }
  .banner__main {
    width: 760px;
    margin: 25px auto 0;
    display: flex;
  }
  .banner__photo {
    width: 100px;
    height: 150px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-right: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .banner__key-value-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .banner__key-value {
    width: 50%;
    font-size: 18px;
    line-height: 2em;
  }
  .banner__key {
    color: $yellow;
    display: inline;
  }
  .banner__value {
    color: #ffffff;
    display: inline;
  }
  .banner__line {
    width: 760px;
    height: 3px;
    margin: 26px auto;
    background-color: $blue;
  }

  .points-title {
    width: 379px;
    height: 48px;
    display: block;
    margin: 60px auto 0;
    background-image: url("../../assets/img/search/points.png");
    background-size: 100%;
    background-position: center;

    color: #2d3233;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }

  .file {
    width: 840px;
    height: 595px;
    margin: 30px auto 0;
    border: 1px solid $blue;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // 进入动画效果
  .detail-enter-active,
  .detail-leave-active {
    transition: all 0.7s;
  }
  .detail-enter {
    opacity: 0;
  }
  .detail-leave-to {
    opacity: 0;
  }
  .detail-enter-to,
  .detail-leave {
    opacity: 1;
  }
</style>